import { Typography } from "@mui/material";
import { useAppContext } from "../../../8_contexts/contextLib";
import moment from "moment";
import "moment/locale/de";
moment.locale("de");


export const LeftBox = () => {
  const { isMobile, isSmallScreen, referenceDate } = useAppContext()

  return (
    // This is the date overview item
    <div style={{
      flex: 1,
      textAlign: "start", // Center along the x-axis
      whiteSpace: "nowrap", // Prevent content from wrapping
    }}> 
    <div>
        <Typography variant="h6" color="primary">
          Willkommen
        </Typography>
      </div>
      <div>
        <Typography color="primary" variant="h4" fontWeight={"bold"}>
          {moment(referenceDate).format(isMobile || isSmallScreen ? "DD.MM." : "DD. MMMM")}
        </Typography>
      </div>
    </div>
  );
};
