import { memo, useEffect, useState } from "react";
import { useAppContext } from "../../../../../8_contexts/contextLib";
import { useNavigate } from "react-router-dom";
import loginFormValidation from "../../LoginFunctions/loginFormValidation";
import FeedbackMessage from "./FeedbackMessage";
import LoginButtons from "../../LeftInformation/LoginButtons";
import LoginFields from "./LoginFields";
import authService from "../../../../../6_services/auth.service";
const {
  combineErrorLists,
} = require("../../../../../4_generalHelpers/_ErrorHandling/combineErrorLists");
const {
  getErrorMessageFromResponse,
} = require("../../../../../4_generalHelpers/_ErrorHandling/getErrorMessageFromResponse");


function LoginCard() {
  const {
    setCurrentUser,
    isAuthenticated,
    setCurrentPage,
    verificationMessage,
    setVerificationMessage,
    verificationUserName,
    setVerificationUserName,
    currentVerificationUser,
    setCurrentVerificationUser,
    verificationUserPassword,
    setVerificationUserPassword,
    loading, 
    setLoading
  } = useAppContext();
  const [email, setEmail] = useState(""); //email
  const [userPassword, setUserPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [combinedErrors, setCombinedErrors] = useState([]);

  useState({});

  const [msg, setMsg] = useState("");

  const onChangeEmail = (e) => {
    setMsg("");
    if (e.target.value.length < 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: [],
        login: []
      }));
    }
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setMsg("");
    setUserPassword(e.target.value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      userPassword: [],
      login:[]
    }));
  };

  const submit = async () => {
    setLoading(true)
    // e.preventDefault();
    const { isValid, errors } = await loginFormValidation(email, userPassword);

    if (isValid) {
      await handleLogin();
    } else {
      // console.log(isValid, errors);
      setErrors(errors);
      setLoading(false)
    }
  };

  const handleLogin = async () => {
    try {
      const response = await authService.login(email, userPassword);
      // console.log(response.request?.status);
      const receivedError = response.request?.status !== 200;
      if (receivedError) {
        const errors = await getErrorMessageFromResponse("login", response)
        // console.log(errors)
        await setErrors(errors);
        setLoading(false)
      } else {
        // Success handling
        // console.log("we trigger this...4")
        await setCurrentUser(authService.getCurrentUser());
      }
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };

  let navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      // console.log("navigate to dashboard")
      setCurrentPage("dashboard");
      navigate("/");
      setLoading(false)
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (verificationMessage) {
      setLoading(true);
      setMsg(
        "Wir haben eine Verifizierungsemail geschickt an: " +
        verificationUserName
      );
      setTimeout(() => {
        setLoading(false);
        setVerificationMessage(false);
        setVerificationUserName("");
        setMsg("");
      }, 10000);
    }
  }, [verificationMessage]);

  useEffect(() => {
    const verifyUser = async () => {
      try {
        await currentVerificationUser?.reload();

        if (currentVerificationUser?.emailVerified) {
          clearInterval(interval);

          await authService.verifyUser(
            currentVerificationUser.email,
            verificationUserPassword
          );
          // console.log("we trigger this...3")
          setCurrentUser(authService.getCurrentUser());
          setCurrentVerificationUser(undefined);
          setVerificationUserPassword("");
        }
      } catch (error) {
        alert(error.message);
      } finally {
        setLoading(false);
        setCurrentVerificationUser(undefined);
        setVerificationUserPassword("");
      }
    };

    let interval;

    if (currentVerificationUser) {
      interval = setInterval(verifyUser, 1000);
    }

    return () => clearInterval(interval);
  }, [currentVerificationUser, verificationUserPassword]);

  useEffect(() => {
    const fixErrors = async () => {
      const newCombinedErrors = await combineErrorLists(errors);
      setCombinedErrors(newCombinedErrors);
    };
    if (errors) {
      fixErrors();
    }
  }, [errors]);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: "green",
        borderTopRightRadius: "15px",
        borderBottomRightRadius: "15px",
        gap: "1em"
      }}
    >
      <div style={{
        marginTop: "2em"
      }}>
        <h1>Login</h1>
      </div>

      <LoginFields
        email={email}
        onChangeEmail={onChangeEmail}
        userPassword={userPassword}
        onChangePassword={onChangePassword}
      />
      <LoginButtons
        loading={loading}
        submit={submit}
        disabled={combinedErrors.length>0}
        text={"anmelden"}
      />
      <FeedbackMessage errors={combinedErrors} msg={msg} />
    </div>
  );
}

export default memo(LoginCard);
