import { memo } from "react";
import "./signUpForm.css";
import { useTheme } from "@mui/styles";
import { CircularProgress } from "@mui/material";

// SignUpCardFields component
function SignUpCardFields({ fields, verificationCodeStore }) {
  const theme = useTheme();

  // Helper function to render each input field with error handling
  const renderInputField = (field) => {
    return (
      <>
        <input
          type={field.type}
          placeholder={field.placeholder}
          name={field.name}
          onChange={field.onChange}
          value={field.value}
          required
          className="input"
        />
        {Object.keys(field.error).map((key, i) => (
          <div key={i} className="error_msg visible">
            {field.error[key]}
          </div>
        ))}
      </>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "90%",
      }}
    >
      {verificationCodeStore.length === 0 ? (
        <>
          {renderInputField(fields.firstName)}
          {renderInputField(fields.lastName)}
          {renderInputField(fields.email)}
          {renderInputField(fields.password)}
          {renderInputField(fields.passwordConfirmation)}
        </>
      ) : (
        <div>
          <CircularProgress
            style={{
              color: theme.palette.levels.green,
              marginTop: "1.5rem",
              marginBottom: "1.5rem",
            }}
            size={"3.25rem"}
          />
        </div>
      )}
    </div>
  );
}

export default memo(SignUpCardFields);
