import { memo } from "react";
import LoginBox from "../../../4_generalHelpers/1_Boxes/LoginBox";
import LeftInformation from "../Login/LeftInformation/LeftInformation";
import SignUpCard from "./SignUpFormComponents/SignUpCard";

function LoginForm({ flipCard }) {
  return (
    <LoginBox>
      <LeftInformation flipCard={flipCard} loginPage={true} />
      <SignUpCard />
    </LoginBox>
  );
}

export default memo(LoginForm);
