import { memo } from "react";
import AccountProfile from "./AccountProfile/AccountProfile";
import PasswordUpdate from "./PasswordProfile/PasswordUpdate";
import UsernameDetail from "./UserName/UsernameDetail";

function ProfileContent() {
  return (
    <div
      style={{
        // display: 'flex', // Establishes a flex container
        flexDirection: 'column', // Arranges children vertically
        // gap: '16px', // Sets a 100px gap between children
      }}
    >
      <AccountProfile />
      <PasswordUpdate />
      <UsernameDetail />
    </div>
  )
}

export default memo(ProfileContent);
