import { memo } from "react";
import { useTheme } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import MyButton from "../../../../4_generalHelpers/2_Buttons/MyButton";
function LoginButtons({ loading, submit,text, disabled, color }) {
  const theme = useTheme();

  return (
    <div>
      {!loading ? (
        <MyButton job={submit} text={text} disabled={disabled} color={color}/>
      ) : (
        <CircularProgress
          style={{
            color: theme.palette.primary.main,
            // marginTop: "1.5rem",
            // marginLeft: "0.5rem",
          }}
          size={"1.8rem"}
        />
      )}
    </div>
  );
}

export default memo(LoginButtons);
