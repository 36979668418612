import React from "react";

const AppBarBox = ({ flexValue, role, children, style = {}, ...props }) => {
  return (
    <div
      role={role}
      style={{
        flex: `0 0 ${flexValue}`,
        overflow: "hidden",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        ...style, // Allow for custom styles to be passed in
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default AppBarBox;
