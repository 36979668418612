import React, { memo, useEffect, useState } from "react";
import MenuTopBarRight from "./MenuTopBarRight";
import MenuTopBarTitle from "./MenuTopBarTitle";
import AppBarBox from "../../../4_generalHelpers/1_Boxes/AppBarBox";
import { useAppContext } from "../../../8_contexts/contextLib";
import { useTheme } from "@mui/styles";
import MenuTopBarUnauthorized from "./MenuTopBarUnauthorized";


function MenuTopBarContent() {
  const theme = useTheme();
  const { currentUser, isAuthenticated } = useAppContext();
  const [flexValues, setFlexValues] = useState({
    left: "33%",
    center: "33%",
    right: "33%",
  });

  const [currentScreenWidth, setCurrentScreenWidth] = useState(window.innerWidth);

  // Update flex values based on the screen width
  const updateFlexValue = () => {
    const screenWidth = window.innerWidth;
    setCurrentScreenWidth(screenWidth);

    let flex = "33%"; // Default for larger screens
    if (screenWidth <= 800) {
      flex = "50%"; // For smaller screens
    }

    setFlexValues({
      left: flex,
      center: flex,
      right: flex,
    });
  };

  // Set up resize listener and cleanup
  useEffect(() => {
    updateFlexValue(); // Initial check
    window.addEventListener("resize", updateFlexValue);

    return () => {
      window.removeEventListener("resize", updateFlexValue);
    };
  }, []);

  return (
    <div
      style={{
        height: "4em",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "100%",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      {isAuthenticated && currentUser ? (
        <>
          {/* LEFT item */}
          <AppBarBox
            role="left-item"
            flexValue={flexValues.left}
            style={{ display: currentScreenWidth < 800 ? "none" : "flex" }}
          >
            IT Projekte von Lernenden
          </AppBarBox>

          {/* CENTER item */}
          <AppBarBox
            role="center-item"
            flexValue={flexValues.center}
            style={{
              overflow: "hidden",
              display: flexValues.center === "50%" ? "" : "flex",
              marginLeft: flexValues.center === "50%" ? "0.1em" : "0",
              justifyContent: "flex-start",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "thin",
              scrollbarColor: "transparent transparent",
              marginInline: "-17px",
            }}
          >
            <MenuTopBarTitle flexValue={flexValues.left} />
          </AppBarBox>

          {/* RIGHT item */}
          <AppBarBox
            role="right-item"
            flexValue={flexValues.right}
            style={{
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "thin",
              scrollbarColor: "transparent transparent",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <MenuTopBarRight />
          </AppBarBox>
        </>) : <>
        {/* CENTER item only */}
        <AppBarBox
          role="center-item"
          flexValue={flexValues.center}
          style={{
            flex: 1,
            display: flexValues.center === "50%" ? "" : "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MenuTopBarUnauthorized/>
        </AppBarBox>
      </>}
    </div>
  );
}

export default memo(MenuTopBarContent);
