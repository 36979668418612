import { memo, useState } from "react";
import "./signUpForm.css";
import { useAppContext } from "../../../../8_contexts/contextLib";
import FeedbackMessage from "../../Login/LoginComponents/MainBox/FeedbackMessage";
import LoginButtons from "../../Login/LeftInformation/LoginButtons";
import SignUpCardFields from "./SignUpCardFields";
import { validateForm } from "../SignUpFormFunctions/validateForm";
import { handleRegister } from "../SignUpFormFunctions/handleRegister";

function SignUpCard() {
  const { verificationCodeStore, setVerificationCodeStore } = useAppContext();

  // Form fields state management
  const [formState, setFormState] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirmation: "",
  });

  // Error state
  const [errors, setErrors] = useState({
    emailErr: {},
    firstNameErr: {},
    lastNameErr: {},
    passwordErr: {},
    passwordConfirmationErr: {},
  });

  const [error, setError] = useState([]);
  const [msg, setMsg] = useState("");

  // Generic onChange handler
  const handleChange = (field) => (e) => {
    setError([]);
    setFormState((prev) => ({ ...prev, [field]: e.target.value }));

    // Clear errors as  types
    if (["email", "firstName", "lastName"].includes(field) && e.target.value.length < 40) {
      setErrors((prev) => ({ ...prev, [`${field}Err`]: {} }));
    }
    if (["password", "passwordConfirmation"].includes(field)) {
      setErrors((prev) => ({
        ...prev,
        passwordErr: {},
        passwordConfirmationErr: {},
      }));
    }
  };



  // Submit function
  const submit = async () => {
    const { isValid, validationErrors } = validateForm(formState);

    if (isValid) {
      setVerificationCodeStore("waiting");
      handleRegister(formState, setError, setVerificationCodeStore, setMsg);
    } else {
      setErrors(validationErrors);  // Update errors after validation
    }
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderTopRightRadius: "15px",
        borderBottomRightRadius: "15px",
        gap: "1em",
      }}
    >
      <div style={{ marginTop: "2em" }}>
        <h1>{verificationCodeStore.length === 0 ? "Registrierung" : "Mailadresse Verifizieren"}</h1>
      </div>

      <SignUpCardFields
        fields={{
          firstName: {
            type: "text",
            placeholder: "Vorname",
            name: "firstName",
            value: formState.firstName,
            onChange: handleChange("firstName"),
            error: errors.firstNameErr,
          },
          lastName: {
            type: "text",
            placeholder: "Nachname",
            name: "lastName",
            value: formState.lastName,
            onChange: handleChange("lastName"),
            error: errors.lastNameErr,
          },
          email: {
            type: "email",
            placeholder: "Email",
            name: "email",
            value: formState.email,
            onChange: handleChange("email"),
            error: errors.emailErr,
          },
          password: {
            type: "password",
            placeholder: "Passwort",
            name: "password",
            value: formState.password,
            onChange: handleChange("password"),
            error: errors.passwordErr,
          },
          passwordConfirmation: {
            type: "password",
            placeholder: "Passwort wiederholen",
            name: "passwordConfirmation",
            value: formState.passwordConfirmation,
            onChange: handleChange("passwordConfirmation"),
            error: errors.passwordConfirmationErr,
          },
        }}
        verificationCodeStore={verificationCodeStore}
      />

      <LoginButtons
        loading={false}
        submit={submit}
        disabled={verificationCodeStore.length > 0}
        text={"registrieren"}
      />
      <FeedbackMessage errors={error} msg={msg} />
    </div>
  );
}

export default memo(SignUpCard);