import { Typography } from "@mui/material";
export const RightBox = () => {

  return (
    <div style={{
      flex: 1,
      textAlign: "end", // Center along the x-axis
      whiteSpace: "nowrap", // Prevent content from wrapping
    }}>
      <div>
        <Typography variant="h6" color="primary">
          Viel Vergnügen
        </Typography>
      </div>
      <div>
        <Typography color="primary" variant="h4" fontWeight={"bold"}>
          KSB-IT
        </Typography>
      </div>
    </div>
  );
};
