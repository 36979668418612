import "./startPage.css";
import React, { memo, useEffect, useState } from "react";
import {Grid2, Paper } from "@mui/material";
import ReactCardFlip from "react-card-flip";
import SignUpForm from "./SignUpForm/SignUpForm";
import LoginForm from "./Login/LoginForm";
import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import AOS from "aos";
import { useAppContext } from "../../8_contexts/contextLib";
import {
  CheckBox,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";

function StartPage() {
  const { isMobile, loading } = useAppContext()
  const [seeLogin, setSeeLogin] = useState(true);
  const [seeRegister, setSeeRegister] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);
  const [showDropdown4, setShowDropdown4] = useState(false);
  const [showDropdown5, setShowDropdown5] = useState(false);

  useEffect(()=>{
    if(!loading){
      AOS.init();
      AOS.refresh(); // Refresh AOS after initialization
    }
  })

  const changeView = () => {
    setSeeLogin(!seeLogin);
    setSeeRegister(!seeRegister);
  };

  if (loading === true) {
    return (
      <div className="background" sx={{
        width: "100%", overflowX: "hidden", // Hide horizontal overflow
      }}>
        <div
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-10em"
          }}
        >
          <div className="monkey">
            <img className="loading" src="./static/images/login/logo512.png" />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="background"
        style={{
          flex: 1, 
          overflowX: "hidden", // Hide horizontal overflow
        }}
      >
        <div className="App">
          <div className="monkey">
            <img style={{ opacity: "0" }} src="./static/images/login/ape.gif" />
          </div>
          <div className="clouds">
            <img style={{ opacity: "0", width: "100%" }} src="./static/images/login/clouds.png" />
          </div>
          <div className="header" data-aos="zoom-in-down" data-aos-duration="1000">
            <div className="headerBody">
            </div>
            <div>
              <div
                container
                style={{
                  flex:1, 
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  
                }}
              >
                <Paper
                  variant="elevation"
                  elevation={5}
                  sx={{ borderRadius: 10, width: isMobile ? "90%" : "50%", maxWidth: 700, minWidth: isMobile ? 300 : 470 }}
                >
                  <Grid2 item xs={12} ss={12} sm={12} md={12} padding={3}>
                    <ReactCardFlip isFlipped={seeLogin} flipDirection="horizontal">
                      <SignUpForm flipCard={changeView} />
                      <LoginForm flipCard={changeView} />
                    </ReactCardFlip>
                  </Grid2>
                </Paper>
              </div>
            </div>
          </div>

          <div className="body">
            <div className="storyline" id="storyline">
              <h1
                className="subTitle"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Was ist
              </h1>
              <h1 className="title" data-aos="fade-up" data-aos-duration="1000">
                KSB-IT?
              </h1>
              <div
                key={1}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div>
                  <img className="left" src="./static/images/story/currentApps.png" />
                </div>
                <div className="rightText">
                  <h1>
                    ksb-it bietet eine Plattform für Lernende um WebApplikationen in einer echten Umgebung zu entwickeln. Dies ist gedacht für begeisterte Programmierer*innen oder für Maturaarbeitsprojekte
                  </h1>
                </div>
              </div>
              <div
                key={2}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="leftText">
                  <h1>
                    Unsere Umgebung bietet ein robustes Setting, bei der auf allgemeine Funktionen wie ein implementierter Loginprozess gemeinsam genutzt werden kann...
                  </h1>
                </div>
                <div>
                  <img className="right" src="./static/images/story/Login.png" />
                </div>
              </div>
              <div
                key={3}
                className="section"
                // style={{width:"50%"}}
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div>
                  <img className="left" src="./static/images/story/storyline1.png" />
                </div>
                <div className="rightText">
                  <h1>
                    Husibuech zeigt beispielsweise auf, wie die zeitliche Belastung bei Lernenden aussieht. Je genauer
                    Aufgaben erfasst werden, umso besser können wir visualisieren, was das bedeutet - sei es durch
                    die Woche hindurch, oder über das ganze Jahr.
                  </h1>
                </div>
              </div>
              <div
                key={4}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="leftText">
                  <br />
                  <br />
                  <h1>Unser Prüfungplan bietet Lernenenden und Lehrpersonen eine klare Übersicht über die Prüfungen,
                  die anstehen. Man hat das ganze Semester auf einen Blick.
                  </h1>
                </div>
                <div>
                  <img className="right" src="./static/images/story/storyline6.png" />
                </div>
                <br />
              </div>
              <br />
              <div
                key={5}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div>
                  <img className="left" src="./static/images/story/chatApp.png" />
                </div>
                <div className="rightText">
                  <h1>Und eine funktionierende ChatApp ohne 2-FA ist aktuell in Ausarbeitung
                  </h1>
                </div>
              </div>
             
              <br />
              <br />
              <br />
              <br />
              <br /> 
              <br />
              <br />
              <br />
              <br />
              <br />
              <div
                key={8}
                className="section"
                // data-aos="fade-up"
                data-aos="flip-left"

                data-aos-duration="1000"
              >
                <div className="leftText">
                  <h1 style={{ color: 'orange' }}> ...und JA! uns gibt es auch "mobile".</h1>
                  <h1>
                    Die mobile Version unserer App ist zwar nicht perfekt, aber
                    sie erlaubt dir, viele Dinge bequem auf dem Handy zu erledigen.
                    Plane dein eigenes Projekt und kontaktiere uns.
                  </h1>
                </div>
                <div>
                  {/* <br /> */}
                  <img className="rightExtra" src="./static/images/story/storyline10.png" />
                </div>
              </div>
            </div>
            <div className="roadmap" id="roadmap">
              <h1
                className="subTitle"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                ROADMAP
              </h1>
              <h1 className="title" data-aos="fade-up" data-aos-duration="1000">
                Neu bei KSB-IT...
              </h1>

              <div
                key={9}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h3>Registrieren</h3>
                <br />
                <ul>
                  <li>Du registrierst dich auf dieser Seite mit deiner SLUZ-Adresse.
                    Danach wirst du eine Mail mit einem Verifizierungslink erhalten und du kannst dich bei Husibuech einloggen.</li>
                </ul>
                <br />
              </div>
            </div>
            <div className="team" data-aos="fade-up" data-aos-duration="1000">
              <h1 className="subTitle">TEAM</h1>
              <h1 className="title">Contributors</h1>
              <div className="teamInner">
                <div className="teamMember">
                  <img src="./static/images/team/Maverick.png" />
                  <h1>Mr. P</h1>
                  <h2>Creator | Admin</h2>
                  <div className="line" />
                  <h3>
                    English & IT teacher, ICT Support
                  </h3>
                  <h3>
                    Kontakt: roman.pfaeffli@sluz.ch
                  </h3>
                </div>
                <div className="teamMember">
                    <img src="./static/images/team/Iceman.png" />
                    <h1>Ms. H</h1>
                    <h2>Chat-Creator</h2>
                    <div className="line" />
                    <h3>Maturaarbeitsprojekt</h3>
                  </div>
                  {/* <div className="teamMember">
                    <img src="./static/images/team/Zoomy.png" />
                    <h1>Aerial Ape</h1>
                    <h2>Project Advisor & Social Media</h2>
                    <div className="line" />
                    <h3>Dapper Ducks Co-Founder & Airline Pilot</h3>
                  </div> */}
              </div>
            </div>
            <div className="faq" data-aos="fade-up" data-aos-duration="1000">
              <h1 className="subTitle">FAQ</h1>
              <h1 className="title">Häufige Fragen</h1>
              <div
                className="question"
                onClick={() => setShowDropdown1(!showDropdown1)}
              >
                <div style={{ width: "100%", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                  <div style={{ width: "95%", marginLeft: "1.5em", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                    <h1>
                      Was ist KSB-IT?
                    </h1>
                  </div>
                  <div style={{ width: "5%", display: 'flex', justifyContent: "center", alignItems: 'center', }}>
                    {showDropdown1 ? <CheckBox /> : <CheckBoxOutlineBlank />}
                  </div>
                </div>
                {showDropdown1 ? (
                  <h2>
                    Plattform für die erarbeitung realistischer WebApplikations Features.
                  </h2>
                ) : null}
              </div>
              <div className="line" />
              <div
                className="question"
                onClick={() => setShowDropdown2(!showDropdown2)}
              >
                <div style={{ width: "100%", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                  <div style={{ width: "95%", marginLeft: "1.5em", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                    <h1>
                      Wer kann KSB-IT nutzen?
                    </h1>
                  </div>
                  <div style={{ width: "5%", display: 'flex', justifyContent: "center", alignItems: 'center', }}>
                    {showDropdown2 ? <CheckBox /> : <CheckBoxOutlineBlank />}
                  </div>
                </div>
                {showDropdown2 ? (
                  <h2>
                    Aktuell ist Husibuech für Lernende und Lehrpersonen im Kanton Luzern zugänglich.
                    Für einen Zugang im Programmierbereicht ist der Administrator zu kontaktieren.
                  </h2>
                ) : null}
              </div>
              <div className="line" />
              <div
                className="question"
                onClick={() => setShowDropdown3(!showDropdown3)}
              >
                <div style={{ width: "100%", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                  <div style={{ width: "95%", marginLeft: "1.5em", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                    <h1>
                      Kostet KSB-IT?
                    </h1>
                  </div>
                  <div style={{ width: "5%", display: 'flex', justifyContent: "center", alignItems: 'center', }}>
                    {showDropdown3 ? <CheckBox /> : <CheckBoxOutlineBlank />}
                  </div>
                </div>
                {showDropdown3 ? (
                  <h2>
                    Nein. Wir stellen backend server und frontend Umgebung zur Verfügung.
                  </h2>
                ) : null}
              </div>
              <div className="line" />
              <div
                className="question"
                onClick={() => setShowDropdown4(!showDropdown4)}
              >
                <div style={{ width: "100%", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                  <div style={{ width: "95%", marginLeft: "1.5em", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                    <h1>
                      Verwendet Husibuech SchulNetz Daten?
                    </h1>
                  </div>
                  <div style={{ width: "5%", display: 'flex', justifyContent: "center", alignItems: 'center', }}>
                    {showDropdown4 ? <CheckBox /> : <CheckBoxOutlineBlank />}
                  </div>
                </div>
                {showDropdown4 ? (
                  <h2>
                    Husibuech ist ein unabhängiges System. Nutzer der Applikation erlauben, die Nutzung der Nutzungsdaten!
                  </h2>
                ) : null}
              </div>
              <div className="line" />
            </div>
            <div className="footer">
              <h1>Copyright © KSB 2024</h1>
            </div>
          </div>
        </div >
      </div >


    );
  }
}

export default memo(StartPage);
