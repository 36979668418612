import axios from "axios";
import { SERVER_URL } from "../2_deployment/constants";
import authHeader from "./auth-header";

class UserService {
  /**
   * Get user information function
   */
  getUserInfo(parameter, id, courseId) {
    // console.log("call on: ", parameter, id, courseId)
    try {
      return axios.post(
        SERVER_URL + "protecteduserinfo/" + parameter,
        !courseId?{ id: id }:{ id: id, course_id: courseId},
        {
          headers: authHeader(),
        }
      );
    } catch (e) {
      console.log("We had a problem with the database query: ", e);
      return [];
    }
  }
  /**
   * Update user information function
   */
  updateUser(type, JsonObject, userId) {
    try {
      // console.log("json,", JsonObject)
      return axios.post(
        SERVER_URL + "userupdate/" + type + "/" + userId,
        JsonObject,
        { headers: authHeader() }
      );
    } catch (error) {
      console.log(error)
      return error;
    }
  }
  /**
   * Delete functions
   */
  delete(type, deleteList, userId) {
    try {
      // console.log(assignmentJson)
      return axios.post(
        SERVER_URL + "delete/" + type + "/" + userId,
        deleteList,
        { headers: authHeader() }
      );
    } catch (error) {
      console.log(error)
      return error;
    }
  }

  deleteUser(id) {
    console.log(id);
    try {
      return axios.get(SERVER_URL + "users/kill/" + id, {
        headers: authHeader(),
      });
    } catch (e) {
      return e;
    }
  }
}
export default new UserService();
