import { memo } from "react";
import { Typography } from "@mui/material";

function ProjectLinkBox() {

  return (
    <div
      justifyContent="start"
      alignItems="start"
      sx={{
        display: {
          xs: "none",
          ss: "block",
          sm: "block",
          md: "block",
          lg: "block",
          xl: "block",
        },
      }}
    >
      <Typography color="primary" align="center">
        <a href="./static/documents/PlanBar_2022_paper.pdf" target="_blank">
          Mehr über das Projekt
        </a>
      </Typography>
    </div>
  );
}

export default memo(ProjectLinkBox);
