import { memo } from "react";
import "./loginFields.css";

function LoginFields({ email, onChangeEmail, userPassword, onChangePassword }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "90%",
      }}
    >
      <input
        type="email"
        placeholder="Email"
        name="email"
        onChange={onChangeEmail}
        value={email}
        required
        className="input"
      />
      <input
        type="password"
        placeholder="Passwort"
        name="userPassword"
        onChange={onChangePassword}
        value={userPassword}
        required
        className="input"
      />
    </div>
  );
}

export default memo(LoginFields);